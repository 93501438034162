import ApiService from './api.service'

export default class UsercostsService {

	async getUsercosts(yearmonth) {
        const response = await ApiService.get('api/usercosts', {yearmonth})
        return response.data.data
    }

    async setUsercostStatus(data) {
        const response = await ApiService.post('api/usercosts/status', data)
        return response.data
    }

	async newUsercost(usercost) {
        const response = await ApiService.post('api/usercosts', usercost)
        return response.data.id
    }

	async updateUsercost(usercost) {
        const response = await ApiService.put('api/usercosts/'+usercost.id, usercost)
        return response.data.id
    }

	async deleteUsercost(id) {
        const response = await ApiService.delete('api/usercosts/'+id)
        return response
    }

	async downloadFileByUserAndType(type, userId, yearmonth) {
        const requestData = {
            method: 'get',
            url: "api/usercosts/download",
            params: {
                type,
                userId,
                yearmonth
            },
            responseType: 'blob',
        }
        const response = await ApiService.customRequest(requestData)
        return response
    }

}
