<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="maincard">

				<Toast/>
                <ConfirmDialog></ConfirmDialog>

                <!-- Táblázat -->
				<DataTable ref="dt" :value="usercosts" dataKey="userid" :paginator="true" :rows="25"
                            v-model:expandedRows="expandedRows"  @row-click="expandRow" @row-expand="expandRowWithExpander"
                            v-model:filters="filters" :globalFilterFields="['homecarer']"
                            csvSeparator=";" :exportFilename="exportFilenameUsercosts"
                            sortField="homecarer" :sortOrder="1" :loading="loading" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,100]"
							currentPageReportTemplate="{first} - {last} / {totalRecords}" class="p-datatable-customers p-datatable-sm p-datatable-hoverable-rows ">
					<template #header>
						<div class="table-header">
                            <div class="p-d-flex p-ai-center">
                                <Button title="Frissít" :icon="(loading) ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" class="p-button-raised p-button-sm p-mr-3" @click="loadData()" />
                                <h5 class="p-m-0 p-d-inline-block">Teljesítések - Költségek rögzítése, jóváhagyása</h5>
                            </div>
                            <div>
                                Év, hó
                                <Calendar id="yearmonth" name="x-usercosts-datum" v-model.lazy="yearmonth" view="month" dateFormat="yy.mm" :yearNavigator="true" yearRange="2000:2030"
                                @change="loadData()" @date-select="loadData()" autocomplete="off"/>
                                <span class="p-input-icon-left">
                                    <i :class="(loadingSearch) ? ['pi', 'pi-spin', 'pi-spinner'] : ['pi', 'pi-search']"/>
                                    <InputText v-model="searchGlobal" placeholder="Keresés..." />
                                </span>
                                <div class="p-d-inline" style="margin-left: 1.5rem">
                                    <Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
                                </div>
                            </div>
						</div>
					</template>

                    <Column :expander="true" headerStyle="width: 3rem"/>
                    <Column field="homecarer" header="Otthonápoló" sortable style="width: 15%; min-width: 8rem; max-width: 15rem;">
                    </Column>
                    <Column field="costs_num" header="Költségek száma" style="width: 6rem">
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{slotProps.data.costs.length}}</span>
                        </template>
                    </Column>
                    <Column field="costs_sum" header="Költségek összege">
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.costs_sum.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="visit_first_fee" header="Vizitdíj 1.hó" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.visit_first_fee.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="visit_first_fee_count" header="1. vizitek száma" :hidden="true" :exportable="true">
                    </Column>
                    <Column field="consulting_fee" header="Konz.díj" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.consulting_fee.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="visit_rest_fee" header="Vizitdíj" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.visit_rest_fee.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="visit_extra_fee" header="Prot. kívüli vizit" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.visit_extra_fee.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="visit_fee_count" header="Vizitek száma" :hidden="true" :exportable="true">
                    </Column>
                    <Column field="advisory_fee" header="Tanácsadás" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ slotProps.data.advisory_fee.toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="advisory_fee_count" header="Tanácsadások száma" :hidden="true" :exportable="true">
                    </Column>
                    <Column field="sum" header="Számla összeg" style="font-weight: 700" sortable>
                        <template #body="slotProps">
                            <span class="l-user-select" @click.stop="">{{ showSum(slotProps.data).toLocaleString() }}</span>
                        </template>
                    </Column>
                    <Column field="files" header="Fájlok" :exportable="false">
                        <template #body="slotProps">
                            <Badge value="Km tábla" class="kmtabla" v-if="slotProps.data.file_kmtabla"></Badge>
                            <Badge :value="slotProps.data.file_vizitlap + ' vizitlap'" class="vizitlap_ok" v-if="slotProps.data.file_vizitlap"></Badge>
                            <Badge value="TIG" class="tig" v-if="slotProps.data.file_tig"></Badge>
                        </template>
                    </Column>
					<Column header="Számlázható" style="width: 9rem">
						<template #body="slotProps">
							<Button v-for="o in usercostStatusOptions" v-bind:key="o.code"
                                    :icon="(o.code=='I')?'pi pi-check':'pi pi-times'"
                                    :class="(o.code=='I' && slotProps.data.status=='I') ? 'p-button-success' : (o.code=='N' && slotProps.data.status=='N') ? 'p-button-primary' : 'p-button-outlined'"
                                    @click="setStatus(slotProps.data, o.code)"
                            ></Button>
						</template>
					</Column>
                    <template #empty>
                        <div style="padding: 3rem; width: 40rem; margin: 0 auto" v-if="!loading">
                            Adj meg a szűrőben érvényes évet és hónapot (pl. "2021.09").
                        </div>
                    </template>

                    <template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <DataTable  ref="dt_costs" :value="slotProps.data.costs" dataKey="id" 
                                        sortField="id" :sortOrder="1"
                                        class="p-datatable-customers p-datatable-hoverable-rows">
                                <template #header>
                                    <div class="table-header">
                                        <div class="p-grid" style="width:100%">
                                            <div class="p-col-3">
                                                <Button label="Új" icon="pi pi-plus" class="p-button-secondary p-mr-2" @click="insertCost(slotProps.data.userid)" v-if="slotProps.data.status!='I'"/>
                                            </div>
                                            <div class="p-col-4">
                                                <span style="display: inline-block; margin: 1rem 1rem 0 0;">Vizitdíj összege:</span>
                                                <span class="l-user-select">{{ showVisitFeeSum(slotProps.data) }}</span>
                                            </div>
                                            <div class="p-col-5">
                                                <Button icon="pi pi-download" label="Km tábla" class="p-button-outlined p-d-inline-block" @click="downloadFile('kmtabla', slotProps.data.userid)" v-if="slotProps.data.file_kmtabla"/>
                                                <Button icon="pi pi-download" label="Vizitlapok" class="p-button-outlined p-d-inline-block" @click="downloadVizitlapFile(slotProps.data.userid, slotProps.data.homecarer)" v-if="slotProps.data.file_vizitlap"/>
                                                <Button icon="pi pi-download" label="TIG" class="p-button-outlined p-d-inline-block" @click="downloadFile('tig', slotProps.data.userid)" v-if="slotProps.data.file_tig"/>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <Column field="usercosttype" header="Típus">
                                    <template #body="slotProps2">
                                        <Dropdown v-if="slotProps.data.status!='I'" 
                                            v-model="slotProps2.data.usercosttype" :options="usercostTypes" optionLabel="name" optionValue="id" 
                                            required="true" :class="{'p-invalid': slotProps2.data.usercosttype == ''}" scrollHeight="20rem"
                                            @change="changeCostType(slotProps, slotProps2)" 
                                        />
                                        <span v-else>{{ showUsercostType(slotProps2.data.usercosttype) }}</span>
                                    </template>
                                </Column>
                                <Column field="cost" header="Költség">
                                    <template #body="slotProps2">
                                        <InputNumber v-if="slotProps.data.status!='I'" 
                                            v-model="slotProps2.data.cost"
                                            @focus="focusCost(slotProps2.data)" @keyup.esc="$event.target.blur(); slotProps2.data.cost=slotProps2.data.editFieldOriginal;"
                                            @keypress.enter="$event.target.blur()" @blur="changeCostCost(slotProps, slotProps2)" 
                                            :inputClass="{'l-saved': slotProps2.data.saved}"
                                        />
                                        <span v-else>{{slotProps2.data.cost.toLocaleString()}}</span>
                                    </template>
                                </Column>
                                <Column>
                                    <template #body="slotProps2">
                                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" 
                                            v-if="slotProps.data.status!='I'" @click="deleteCost(slotProps2.data.id)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </template>
                        </Card>
                    </template>
                </DataTable>
			</div>
		</div>
	</div>

</template>

<script>
//import { mapGetters, mapActions } from 'vuex'
import UsercostsService from '@/service/backend/usercosts.service'
import CostService from '@/service/backend/cost.service'

export default {
	data() {

        const usercostStatusOptions = [{'code':'I', 'value':'I'}, {'code':'N', 'value':'N'}]

        return {
			usercosts: null,
			usercostDialog: false,
			deleteUsercostDialog: false,
            filters: {
                'global': {value: null},
            },
            submitted: false,
            searchGlobal: "",
            loading: true,
            loadingSearch: false,
            searchInterval: null,

            expandedRows: [],

            usercostStatusOptions,
            usercostTypes : [],
            newCostId: 1000000000,

		}
	},
    usercostsService: null,
    costService: null,
	created() {
        //this.setDefaultYearMonth()
        this.yearmonth = this.$dayjs().subtract(1, 'month').toDate() 
        //this.yearmonth = this.$dayjs().toDate() // teszt
        //this.filters.global.value = 'Ver' // teszt

		this.usercostsService = new UsercostsService()
        this.costService = new CostService()

        this.costService.getCostsForSelect()
        .then(result => {
                this.usercostTypes = result
        })
        this.loadData();
    },
    beforeMount() {
    },
	mounted() {
	},
	methods: {
/*        ...mapActions({
            getCodeStore: 'codeStore/loadCodeStore'
        }), */
/*        setDefaultYearMonth(){
            let year  = this.$dayjs().year()
            let month = this.$dayjs().month() // 0-11
            if(month == 0){
                month = "12"
                year = year - 1
            }else
            if(month < 10){
                month = "0" + month
            }
            this.yearmonth = year + "." + month
        },
        yearmonthParam(){
            let yearmonthparam = "";
            if(typeof(this.yearmonth) == "string"){
                yearmonthparam = this.yearmonth.replace('.','')
            }else{
                yearmonthparam = this.$dayjs(this.yearmonth).format('YYYYMM')
            }
            return yearmonthparam
        },
*/
        evdateParam(){
            // TODO 28 helyett hónap utolsó napja
            return this.$dayjs(this.yearmonth).format('YYYY-MM') + '-28'
        },
        loadData() {
            this.loading = true
            this.expandedRows = []

            let yearmonthparam = this.$dayjs(this.yearmonth).format('YYYYMM')
            this.usercostsService.getUsercosts(yearmonthparam)
            .then( result => {
                this.usercosts = result
                this.loading = false
            })
        },
        onFilter() {
            this.loadingSearch = false
        },

		exportCSV() {
            this.usercosts.forEach(v => { 
                v.costs_num = v.costs.length;
                v.sum = this.showSum(v);
                // Anita kérésére 22.05.08-án az elosztásos módszerrel - de ez nem az legjobb, mert mi van ha változik az összeg? 
                // Valójában el kellene tárolni az összeg mellé, hogy mennyi a darabszám. --- DB usercostmonth tábla módosítása megvan, controllerben és resource-ban megvan, 
                /*
                v.visit_first_amount = v.visit_first_fee / 6000
                v.visit_amount = (v.visit_rest_fee + v.consulting_fee + v.visit_extra_fee) / 3000
                v.advisory_amount = v.advisory_fee / 1500
                */
            })
			this.$refs.dt.exportCSV()
		},
        filterIgenNem(value, filter) {
            if(filter)
                return (value == 'Igen') == filter
            else
                return true
        },
        setStatus(data, new_status){

            // TODO - ez még a VisitRequest
            const old_status = data.status // optimisták vagyunk, előre beállítjuk, de ha baj van, erre vissza lehessen állni
            
            if(old_status != new_status){

                data.yearmonth = this.$dayjs(this.yearmonth).format('YYYYMM')
                data.status = new_status
                this.usercostsService.setUsercostStatus(data)
                .then( result => {
                    data.ucmid = result
                })
                .catch( error => {
                    data.status = old_status
                    console.log(error)
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
                })
            }

        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].userid !== e.data.userid ){
                this.expandedRows = this.usercosts.filter(p => p.userid == e.data.userid);
                this.correctDatatableColspan()
            }else{
                this.expandedRows = []
            }
            
        },
        expandRowWithExpander() {
            if(this.expandedRows.length === 2)
                this.expandedRows.shift()
            else
                this.correctDatatableColspan()
        },
        correctDatatableColspan(){
                this.$nextTick(() => {
                    document.getElementsByClassName("p-datatable-row-expansion")[0].children[0].colSpan=12; // DataTable bug, a hidden oszlopokat is beleszámolja
                });
        },
        insertCost(userid) {

            // Megkeressük a hiányzó típust
            const len = this.usercostTypes.length // selectorder szerint rendezve van
            let index = 0
            let maxTypeIndex = -1
            let i = 0
            while(i<len && index >= 0){
                index = this.expandedRows[0].costs.findIndex( x => {
                            return x.usercosttype == this.usercostTypes[i].id
                        })
                if(index != -1){
                    maxTypeIndex = i
                }
                i++
            }
            
            maxTypeIndex++
            let maxType = null
            let defaultCost = null
            if(len == 0 || maxTypeIndex < len){
                maxType = this.usercostTypes[maxTypeIndex].id
                //defaultCost = this.usercostTypes[maxTypeIndex].cost // NEM JÓ, mert nem lesz elmentve! 
            }

            this.expandedRows[0].costs.push({
                id: this.newCostId,
                cost: defaultCost,
                evdate1: this.evdateParam(),
                usercosttype: maxType,
                userid: userid,
                yearmonth: this.$dayjs(this.yearmonth).format('YYYYMM')
            })
            this.newCostId++
        },
        deleteCost(id) {

            const index = this.expandedRows[0].costs.findIndex( x => {
                                return x.id == id
                          })

            if(id < 1000000000){
                this.$confirm.require({
                    message: 'Biztos töröljük a költséget?',
                    header: 'Törlés megerősítés',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.usercostsService.deleteUsercost(id)
                        .then( () => {
                            this.expandedRows[0].costs.splice(index, 1)
                        }).catch( error => {
                            this.$toast.add({severity:'danger', summary:'Hiba', detail:'Valami hiba történt!', life: 3000})
                            console.log(error)
                        })
                    }
                })
            }else{
                this.expandedRows[0].costs.splice(index, 1)
            }
        },
        changeCostType(usercost, cost) {
            if(cost.data.usercosttype == '')
                return;

            this.saveCost(cost)

        },
        focusCost(data) {
            data.editFieldOriginal = data.cost
            if(data.cost == null){
                const defaultCost = this.usercostTypes.find( x => { return x.id == data.usercosttype }).cost
                if(defaultCost > 0) {
                    data.cost = defaultCost
                }
            }
        },
        changeCostCost(usercost, cost) {

            setTimeout(() => { 

                if(cost.data.cost != cost.data.editFieldOriginal && this.notEmpty(cost.data.cost)){

                    const len = usercost.data.costs.length
                    let sum = 0
                    for(let i=0; i<len;i++){
                        sum += Math.round(usercost.data.costs[i].cost)
                    }
                    usercost.data.costs_sum = sum
                    
                    this.saveCost(cost)

                }

            }, 50);

        },
        async saveCost(cost) {

            if( !(this.notEmpty(cost.data.usercosttype) && this.notEmpty(cost.data.cost)) ){
                return
            }

            cost.data.saved = false
            try{
                if(cost.data.id < 1000000000){  // update
                    await this.usercostsService.updateUsercost(cost.data)
                }else{
                    cost.data.id = await this.usercostsService.newUsercost(cost.data)
                }
                cost.data.saved = true
                this.$toast.add({severity:'success', summary:'Siker', detail:'Mentve!', life: 1000})
                // TODO inkább valami finomabb, a mezőnél zöld elő és eltűnő pipa
            }catch(error){
                console.log(error)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Hiba a rögzítésnél!', life: 3000})
            }
        },
        showUsercostType(typeid) {
            return this.usercostTypes.find( x => { return x.id == typeid }).name
        },
        showVisitFeeSum(data){
            const sum = parseInt(data.consulting_fee) + parseInt(data.visit_first_fee) + parseInt(data.visit_rest_fee) + parseInt(data.visit_extra_fee) + parseInt(data.advisory_fee)
            return sum.toLocaleString()
        },
        showSum(data){
            const sum = parseInt(data.costs_sum) + parseInt(data.consulting_fee) + parseInt(data.visit_first_fee) + parseInt(data.visit_rest_fee) + parseInt(data.visit_extra_fee) + parseInt(data.advisory_fee)
            return sum
        },
        notEmpty(val) {
            // TODO ez is lehetne valami mixin, sok helyen kéne használni
            return val!==null && val!=='' && val!==undefined
        },
        async downloadFile(fileType, userId) {

            let type = ''
            switch (fileType) {
                case 'kmtabla':  type = 'Km tábla';             break;
                case 'vizitlap': type = 'Vizitlap';             break;
                case 'tig':      type = 'Teljesítési igazolás'; break;
                default: break;
            }
            const yearmonthparam = this.$dayjs(this.yearmonth).format('YYYYMM')

            try{
                const downloadedFile = await this.usercostsService.downloadFileByUserAndType(type, userId, yearmonthparam)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const fileName = Buffer.from(downloadedFile.headers['x-filename'], 'base64')
                const blo = new Blob([downloadedFile.data])
                const url = window.URL.createObjectURL(blo)

                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },
        async downloadVizitlapFile(userId, homecarer) {

            const type = 'Vizitlap'
            const yearmonthparam = this.$dayjs(this.yearmonth).format('YYYYMM')

            try{
                const downloadedFile = await this.usercostsService.downloadFileByUserAndType(type, userId, yearmonthparam)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const blo = new Blob([downloadedFile.data])
                const url = window.URL.createObjectURL(blo)

                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', "Vizitlapok-"+yearmonthparam+"-"+homecarer+".zip") 
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },

    }, 
    computed: {
        exportFilenameUsercosts: function(){
            return "Teljesitesek_" + this.$dayjs().format('YYYY.MM.DD')
        }    
    },
    watch: {
        searchGlobal(val) {
            clearTimeout(this.searchInterval)
            this.searchInterval = setTimeout(() => {
                this.loadingSearch = true
                setTimeout(() => { this.filters['global'].value = val; this.onFilter(); }, 20)
            }, 600)
        }
    },
}
</script>
<style>
.p-datatable-tbody {
    cursor: pointer;
}
.p-datatable-tbody tr td {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}
.p-datatable-row-expansion {
    cursor: auto;
}
.p-card .p-card-content {
    padding: 0 0 0.2rem 0;
}
</style>

<style scoped lang="postcss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.usercost-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .usercost-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.p-badge{
    border-radius:2px;
    padding:0 .4rem .2em .4rem;
    text-transform:uppercase;
    font-weight:700;
    font-size:9px;
    letter-spacing:.3px; 
    margin-right: 6px;
}

.p-badge.kmtabla{
    background-color:#b3e5fc;
    color:#23547b;
    margin-top: .2rem;
}
.p-badge.vizitlap_ok{
    background-color:#c8e6c9;
    color:#256029;
    margin-top: .2rem;
}
.p-badge.vizitlap_no{
    background-color:#ffcdd2;
    color:#c63737;
    margin-top: .2rem;
}
.p-badge.tig{
    background-color:#feedaf;
    color:#8a5340;
    margin-top: .2rem;
}


td button {
    margin-right: 1rem;
}

#maincard {
    padding-top: 0px;
}

h5 {
    padding-left: 1.6rem;
    padding-bottom: 0.6rem;
}

.l-saved {
  animation: pulse 1s;
}

.l-user-select {
    cursor: text;
    -webkit-user-select: all;  /* Chrome all / Safari all */
    -moz-user-select: all;     /* Firefox all */
    -ms-user-select: all;      /* IE 10+ */
    user-select: all;          /* Likely future */   
}

@keyframes pulse {
  0%   { background-color: white;   }
  50%  { background-color: #689F38; }
  100% { background-color: white;   }
}

@media screen and (max-width: 960px) {
	::deep() .p-datatable {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}

	::deep() .p-usercostbar {
		flex-wrap: wrap;

		.p-button {
			margin-bottom: .25rem;
		}
    }
    
}
</style>
