import ApiService from './api.service'

export default class CostService {

	async getCosts() {
        const response = await ApiService.get('api/costs')
        return response.data.data
    }

	async getCostsForSelect(_params) {
        const response = await ApiService.get('api/costs/select', _params)
        return response.data.data
    }

	async newCost(cost) {
        const response = await ApiService.post('api/costs', cost)
        return response.data.id
    }

	async updateCost(cost) {
        const response = await ApiService.put('api/costs/'+cost.id, cost)
        return response.data.id
    }

	async deleteCost(id) {
        const response = await ApiService.delete('api/costs/'+id)
        return response
    }

}
